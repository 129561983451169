/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; background-color: black;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
p, h1, h2, h6, li, label, span {color: white;}
button {
  background-color: black;
  color: white;
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.backdrop {
  filter: blur(500px);
}